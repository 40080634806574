import Loader from 'assets/icons/loader.svg';
import './Loading.scss';

export const Loading = () => {
  return (
    <div className='Loading'>
      <img src={Loader} alt='loading' />
    </div>
  );
};
