import { lazy } from 'react';
const Login = lazy(async () => await import('pages/Login/Login'));
const NotFound = lazy(async () => await import('pages/NotFound/NotFound'));
const Company = lazy(async () => await import('pages/Company/Company'));
const Home = lazy(async () => await import('pages/Home/Home'));
const Location = lazy(async () => await import('pages/Home/Location'));
const Otp = lazy(async () => await import('pages/Otp/Otp'));

export const routes = [
  {
    path: '*',
    name: 'NotFound',
    exact: false,
    component: NotFound,
    protected: true,
  },
  {
    path: '/',
    name: 'Home',
    exact: true,
    component: Home,
    protected: true,
  },
  {
    path: '/:id',
    name: 'Location',
    component: Location,
    protected: true,
  },
  {
    path: '/company',
    name: 'Company',
    exact: true,
    component: Company,
    protected: true,
  },
  {
    path: '/login',
    name: 'Login',
    exact: true,
    component: Login,
    protected: false,
  },
  {
    path: '/validate-otp',
    name: 'ValidateOtp',
    exact: true,
    component: Otp,
    protected: false,
  },
];

export default routes;
