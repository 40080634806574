import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { validate } from 'api';

const PrivateRoute = ({ children, isAuth, path, protect }) => {
  let location = useLocation();
  const token = localStorage.getItem('token');

  (async () => {
    try {
      const response = await validate({ token });
      if (!response?.data?.valid && isAuth) {
        localStorage.removeItem('token');
        window.location.reload();
      }
    } catch (err) {
      console.error('Something went wrong.');
    }
  })();

  if (!isAuth) {
    return <Navigate to='/login' state={{ from: location }} replace />;
  }

  return children;
};

export default PrivateRoute;
