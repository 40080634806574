import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import logo from 'assets/images/logo.svg';
import userIcon from 'assets/images/user.svg';
import logoutIcon from 'assets/images/logout.svg';
import './Header.scss';
import { useParams } from 'react-router-dom';
import * as api from 'api';
import { useNavigate } from 'react-router-dom';
import backIcon from 'assets/icons/back.svg';

const Header = () => {
  const params = useLocation();
  const navigate = useNavigate();
  const { id } = useParams();
  const [locationAddress, setLocationAddress] = useState('~');
  const [locationName, setLocationName] = useState('~');
  const name = localStorage.getItem('name');

  const handleLogout = () => {
    localStorage.removeItem('token');
    window.location.reload();
  };

  useEffect(() => {
    if (id) {
      (async () => {
        const data = await api.getLocation(id);
        if (data?.address) setLocationAddress(data.address);
        if (data?.name) setLocationName(data.name);
      })();
    }
  }, [id]);

  return (
    <div className='Header'>
      <div className='logo'>
        <img src={logo} alt='logo' />
      </div>
      <div className='header-wrapper'>
        <div className='page-title'>
          {id && (
            <img
              className='go-back-icon'
              src={backIcon}
              alt='Go Back'
              onClick={() => navigate(-1)}
            />
          )}
          {params.pathname === '/' && 'Baustellen'}
          {params.pathname === '/company' && 'Unternehmen'}
          {params.pathname === '/sureties' && 'Bürgscheine'}
          {id && (
            <div className='page-title-wrapper'>
              Projekt
              <div className='page-title-address'>
                <b>{locationName}</b> - {locationAddress}
              </div>
            </div>
          )}
        </div>
        <div className='user'>
          {name}
          <img src={userIcon} alt='user' aria-describedby={'id'} />
          <img
            className='logout-icon'
            src={logoutIcon}
            alt='logout'
            onClick={handleLogout}
          />
        </div>
      </div>
    </div>
  );
};

export default Header;
